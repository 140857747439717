// 动态添加script
export const addScriptSync = async ({
    id = '',
    src = '',
}) => {
    console.log(id, src)
    return new Promise((resolve, reject) => {
        let el = document.getElementById(id);
        if(el) resolve();
        let script = document.createElement("script");
        script.id = id
        script.type = `text/javascript`
        script.src = src
        document.body.appendChild(script);
        script.onload = res => {
            resolve(res)
        };
    });
};


// 检测类型
export const checkType = (obj, type) => new RegExp(type, 'i').test(Object.prototype.toString.call(obj));

// 检测平台类型
export const checkUserAgent = (type) => {
    let userAgent = navigator.userAgent
    switch(type) {
        case 'phone': 
            return /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i.test(userAgent);
        
    }
}

// 格式化路由参数
export const formatLocation = location => {
    let query = {}
    let arrs = (location.search.split("?")?.[1]?.split("&") || []).map(it => it.split("="));
    arrs.map(it => {
        if(it.length != 2) return;
        let [k, v] = it
        query[k] = v
    });
    return { ...location, query };
};

// JSON.parse
export const parse = (str) => {
    let obj = {}
    try{
        obj = JSON.parse(str)
    }catch(err){
        console.log(err)    
    }
    return obj;
}

// 获取文件base64
export const getBase64 = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};

// base64 转 blob
export const dataURLtoBlob = (dataurl) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
};

// blob 转 file
export const blobToFile = (blob) => {
    return new window.File([blob], this.files[0].name, { type: this.files[0].type });
};

//将腾讯/高德地图经纬度转换为百度地图经纬度
export const qqMapTransBMap = (lng, lat) => {
    let x_pi = Math.PI * 3000.0 / 180.0;
    let x = lng;
    let y = lat;
    let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
    let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
    let lngs = z * Math.cos(theta) + 0.0065;
    let lats = z * Math.sin(theta) + 0.006;
    return {
        longitude: lngs,
        latitude: lats
    }
};

// 将百度地图经纬度转换为腾讯/高德地图经纬度
export const bMapTransQQMap = (lng, lat) => {
    let x_pi = Math.PI * 3000.0 / 180.0;
    let x = lng - 0.0065;
    let y = lat - 0.006;
    let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
    let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
    let lngs = z * Math.cos(theta);
    let lats = z * Math.sin(theta);
    return {
        longitude: lngs,
        latitude: lats    
    }
};

/**
 * @name 格式化金额
 * @param {Number} number 
 * @param {Number} places 
 * @param {String} symbol 
 * @param {String} thousand 
 * @param {String} decimal 
 * @returns 
 */
export const formatMoney = (number, places, symbol, thousand, decimal) => {
    number = number || 0;
    places = !isNaN(places = Math.abs(places)) ? places : 2;
    symbol = symbol !== undefined ? symbol : "$";
    thousand = thousand || ",";
    decimal = decimal || ".";
    let negative = number < 0 ? "-" : "",
        i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
        j = i.length
    j = j > 3 ? j % 3 : 0;
    let nums = Math.abs(number - i).toFixed(places).slice(2)
    return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? (nums == 0 ? '' : decimal + nums) : "");
}