import { useState } from "react";
import { message } from "antd";

import { parse } from "@/utils/utils";
import { fetchLogin } from "@/services/user";
import API from "@/services/requestAPI";
const { Logins, Regions } = API

const Users = () => {
    
    const [user, setUser] = useState(parse(window.localStorage.getItem("User")));
    const [regionList, setRegionList] = useState([]);
    const [currentRegion, setCurrentRegion] = useState({});

    const effects = {
        // 登录
        login: async (params = {}) => {
            if((!params.login_type || params.login_type == 'account') && !(params.pwd || params.login_code)) {
                message.warning(`账号密码有误!`);
                return { success: false }
            }
            if(params.login_type && !params.code) {
                message.warning(`授权错误!`);
                return { success: false }
            }
            let { data, response: r } = await fetchLogin(params);
            let isOk = r && r.status == 200 && data.Data != -1
            if(isOk) {
                let { JWT, Login: { Pwd, ...d } } = data.Data || {}
                // 测试 start
                // d.UserType = 90
                // d.MgRgIds = `1,2`
                // 测试 end
                if( ![ 90, 91, 92, 99 ].includes(d.UserType) ) {
                    message.warning(`权限不足, 请重新登录!`, 5);
                    return { success: false }
                } else {
                    if(d.UserType != 99) {
                        d.mgRgIds = (d.MgRgIds?.split?.(",") || []).filter(it => it).map(it => it - 0);
                        if(!d.mgRgIds?.length) {
                            message.warning(`区域权限不足, 请重新登录!`, 5);
                            return  { success: false }
                        }
                    }
                }
                window.localStorage.setItem("JWT", JSON.stringify(JWT));
                window.localStorage.setItem("User", JSON.stringify(d));
                setUser(d)
            } else {
                message.error(data?.Msg || '登录失败!');
            }
            return { success: isOk }
        },
        // 退出登录
        logout: () => {
            window.localStorage.clear()
            window.location.replace(`/user/login`);
        },
        // 获取用户相关数据
        fetchCurrent: async () => {
            let hide = message.loading(`请稍候...`, 0)
            let { data: d, response: r } = await Logins.findOne({ Id: user.Id });
            hide()
            let isOk = r && r.status == 200
            if(!isOk) return isOk;
            // 测试 start
            // d.UserType = 90
            // d.MgRgIds = `1,2,3`
            // 测试 end
            window.localStorage.setItem("User", JSON.stringify(d));
            d.mgRgIds = (d.MgRgIds?.split?.(",") || []).filter(it => it).map(it => it - 0);
            
            if(![ 90, 91, 92, 99 ].includes(d.UserType)) {
                this.logout();
                setTimeout(() => {
                    message.warning(`权限不足, 请重新登录!`, 5);
                }, 300)
                return false
            }
            setUser(d)
            let { data: list, response: r1 } = await Regions.find({ limit: -1 });
            isOk = r1 && r1.status == 200
            if(!isOk) return isOk
            if(d.UserType != 99) {
                if(!d.mgRgIds?.length) {
                    this.logout();
                    setTimeout(() => {
                        message.warning(`区域权限不足, 请重新登录!`, 5);
                    }, 300)
                    return false
                }
                list = list.filter(it => d.mgRgIds.includes(it.Id));
            }
            let current = list[0] || {}
            setCurrentRegion(current);
            setRegionList(list);
            return isOk
        },
        // 切换当前区域
        changeRegion: ({ regionId } = {}) => {
            let d = regionList.find(it => it.Id == regionId);
            setCurrentRegion(d);
        },
    };

    const dispatch = ({ type, payload } = {}) => {
        return type && effects?.[type] 
            ? effects?.[type]?.(payload)
            : function () {
                console.log(`${type} is not found!`);
            }
        ;
    };
    return {
        dispatch,
        state: {
            user,
            currentRegion,
            regionList,
        }
    };
};

export default Users;