import Loadable from '@/components/Loadable';

// const BasicLayout = Loadable(import('@/layouts/BasicLayout'));
const RegionsLayout = Loadable(import('@/layouts/RegionsLayout'));

const Logins = Loadable(import('@/pages/Users/login'));
const RegionalFinancial = Loadable(import('@/pages/RegionalFinancial'))
// const Workpatch = Loadable(import('@/pages/Workpatch'));
const DetailList = Loadable(import('@/pages/RegionalFinancial/detailList'));
// const MovingDrivers = Loadable(import('@/pages/MovingDrivers'));
const NotFound = Loadable(import('@/pages/404'))

const routes = [
    {
        path: "/",
        name: "index",
        redirects: "/finance/region"
    }, 
    {
        path: '/user/login',
        name: 'login',
        component: Logins,
    },
    {
        path: '/finance',
        name: "finance",
        component: RegionsLayout,
        children: [
            {
                path: '/finance/region',
                name: 'regionalFinancial',
                component: RegionalFinancial
            },
            {
                path: '/finance/tables',
                name: 'tables',
                component: DetailList,
            }, 
        ]
    },
    // {
    //     path: 'movingDrivers',
    //     name: 'movingDrivers',
    //     component: MovingDrivers,
    // },
    // {
    //     path: '/tables',
    //     name: 'main-tables',
    //     component: Tables,
    // }, 
    // {
    //     path: '/workpatch',
    //     name: 'workpatch',
    //     component: Workpatch,
    //     children: [
    //         {
    //             path: '/workpatch/tables',
    //             name: 'tables',
    //             component: Tables
    //         },
    //         {
    //             path: '/workpatch/login',
    //             name: 'w-login',
    //             component: Logins,
    //         }
    //     ]
    // }, 
    {
        path: '*',
        name: 404,
        component: NotFound,
    }
];

export default routes;