import { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useNavigate,
} from 'react-router-dom';
import { ConfigProvider } from "antd";
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale/zh_CN';

import routes from '@/routes'
import StoreProvider from '@/models/creater/provider.js';
import { formatLocation } from "@/utils/utils";

import './App.css';

let location = window.location

const Redirect = ({ to }) => {
    let navigate = useNavigate();
    useEffect(() => {
        navigate(to);
    });
    return null;
};

const redirectRoute = route => (
    <Route
        key={ route.name }
        path={ route.path }
        element={ <Redirect to={ route.redirects } /> }
    />
);

const simpleRoute = route => (
    route.redirects
    ? redirectRoute(route)
    : (
        <Route
            key={ route.name }
            path={ route.path }
            index={ route.index }
            element={ <route.component { ...route } location={ formatLocation(location) } /> }
        />
    )
);

const mainRoute = route => (
    <Route 
        key={ route.name } 
        path={ route.path } 
        index={ route.index }
        element={ <route.component { ...route } location={ formatLocation(location) } /> }
    >
        { route.children.map(it => simpleRoute(it)) }
    </Route>
);

const App = () => (
    <StoreProvider>
        <ConfigProvider locale={locale}>
            <Router>
                <Routes>
                { routes.map((route, i) => ( 
                    route.children && route.component
                        ? mainRoute(route)
                        : simpleRoute(route)
                )) }
                </Routes>
            </Router>
        </ConfigProvider>
    </StoreProvider>
);

export default App;
