
import request from "./request";
 
/** 登录
 *    login_type: 登录类型（open_code为微信开放平台授权登录、qywx_code为企业微信授权登录、空/pwd默认为账号密码登录）
 *    login_code: 登录账号
 *    pwd: 登录密码
 *    code：登录code（login_type为open_code/qywx_code时，必传）
 * */ 
export const fetchLogin = data => {
    let p = Object.entries(data)?.map(([k, v]) => `${k}=${v}`).join('&');
    return request(`/driver_api/rule_login/bg?${p}`, { getResponse: true });
};
