
import { message } from "antd";
import { parse } from "@/utils/utils";

const codeMessage = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
};
const errCode = Object.entries(codeMessage).map(([k, v]) => k).filter(it => it >= 401);

const request = async (url, { method = "GET", headers = {}, data, getResponse = false, getOrgin = false } = {}) => {
    let jwt = parse(window.localStorage.getItem("JWT"));
    if(jwt?.Token) {
        headers = {
            'Authorization': `Bearer ${ jwt.Token }`,
            ...headers,
        }
    }
    let ops = { method, headers }
    if(data){
        ops.body = JSON.stringify(data) 
    }
    let response = await fetch(url, ops);
    if(errCode.includes('' + response.status)){ 
        message.error(codeMessage[response.status]);
        if(response.status == 401) {
            window.localStorage.clear()
            window.location.href = '/user/login'
        } 
        try{
            let cb = await response.json();
            return !getResponse ? cb : { data: cb, response };
        }catch(err){
            return !getResponse ? null : { data: null, response };
        }
    }
    if(getOrgin) return response;

    let cb = await response.json();
    return !getResponse ? cb : { data: cb, response };
};

export default request;