

import users from '@/models/users'
import tests from '@/models/tests'

const config = {
    users,
    tests,
};

export default config;