import { Spin } from "antd";

import './loading.css';

const Loading = ({ error }) => {
    const onReflesh = () => {
        window.location.reload()
    };

    if (error) {
        console.error(error);
        return (
            <div>
                加载错误, 请<span style={{ color: "#1890ff" }} onClick={ onReflesh }>刷新</span>页面
            </div>);
    }
    return (
        <div className="box-loading flex-col justify-center">
            <Spin spinning={true} size="large" />
        </div>
    );
};

export default Loading;