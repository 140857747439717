import ReactLoadable from 'react-loadable';
import Loading from './loading'

const Loadable = compPromise => {
    return ReactLoadable({
        loader: () => compPromise,
        loading: Loading
    });
};
export default Loadable;
