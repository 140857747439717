/**
* 
* @authors NeilYQ (360842060@qq.com)
* @date    2021-01-29 16:24:26
* @version 1.0.0
*/

import request from "@/services/request";

const checkType = (obj, type) => new RegExp(type, 'i').test(Object.prototype.toString.call(obj));

const formatParams = (params = {}) => {
    let field = ['load', 'limit', 'offset', 'sortby', 'order', 'getcounts', 'page', 'fields'];
    let query = '', others = '',
        queryArr = [], otherArr = [],
        keyArr = Object.keys(params);

    if (!keyArr.length) return '';
    keyArr.map(key => {
        if (key == 'search') return;
        if (field.includes(key)) {
            otherArr.push(`${key}=${params[key]}`);
        } else {
            queryArr.push(`${key}:${params[key]}`);
        }
    });

    if (params.search) {
        let t = JSON.parse(JSON.stringify(params.search))
        if (checkType(t, 'object')) {
            params.search = Object.keys(t).map(it =>
                checkType(t[it], 'array')
                    ? t[it].map(i => `${it}>${i}`).join('|')
                    : `${it}>${t[it]}`
            ).join('|');
        }
        params.search.length && queryArr.push(`search:${params.search}`);
    }

    let qLen = queryArr.length,
        oLen = otherArr.length;

    query = qLen > 0 ? `query=${queryArr.join(',')}` : '';
    others = oLen > 0 ? otherArr.join('&') : '';

    if (qLen > 0 && oLen > 0) others = '&' + others;

    return '?' + query + others;
}

let Factory = function (path, table, name) {
    let CRUD = {
        // 创建记录
        create: (name, params) => request(`${path}/${name}`, { method: 'POST', getResponse: true, data: params }),
        // 删除记录
        del: (name, { Id }) => request(`${path}/${name}/${Id}`, { method: 'DELETE', getResponse: true }),
        // 更新记录
        update: (name, { Id, ...params }) => request(`${path}/${name}/${Id}`, { method: 'PUT', getResponse: true, data: params }),
        // 查询单条记录
        getById: (name, { Id, ...params }) => request(`${path}/${name}/${Id}${formatParams(params)}`, { method: 'GET', getResponse: true }),
        // 查询多条记录
        getByQuery: (name, params) => request(`${path}/${name}${formatParams(params)}`, { method: 'GET', getResponse: true }),
        // 修改多对多关系
        updateRelated: (name, { Id, Add, Del, Field }) => request(`${path}/${name}/m2m/part/${Id}${Field ? `?m2m_field=${Field}` : ''}`, { method: 'PATCH', getResponse: true, data: { Add, Del } }),
    }
    return {
        insert: (params) => CRUD.create(table[name], params),    // 创建记录
        update: (params) => CRUD.update(table[name], params),    // 更新记录
        remove: (params) => CRUD.del(table[name], params),       // 删除记录
        findOne: (params) => CRUD.getById(table[name], params),   // 单条查询
        find: (params) => CRUD.getByQuery(table[name], params),  // 多条查询

        updateRelated: (params) => CRUD.updateRelated(table[name], params), // 修改多对多关系
    }
};

// 实例化
let api = {}
let path = "/driver_api"
let table = {
    "login": "login", // 后台登录用户
	"region": "region", // 区域
    "regionWallet": "region_wallet", // 区域钱包
    "rgWalletItem": "rg_wallet_item", // 合作区域账户余额
}
for (let item in table) {
    let key = item.replace(item[0], item[0].toUpperCase());
    api[`${key}s`] = new Factory(path, table, item);
}

export default api